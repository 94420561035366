import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Skeleton,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { AccountCircle, ArrowDownward, CopyAll } from '@mui/icons-material';
import { setProfile, setLogout, setPartnerEmail, setPromoCode, setTesterMode } from '../state';
import { TokenPurchase } from 'components/TokenPurchase';
import { addPartner, getUser } from 'components/ApiActions';
import { CustomInputTextField } from 'components/CustomInputTextField';
import { PartnerObject } from 'components/PartnerObject';
import { CustomStack } from 'components/CustomStack';
import { CustomButton } from 'components/CustomButton';
import { SEO } from 'components/SEO';

export const Profile = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery('(min-width: 1000px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.global.user);
  const profile = useSelector((state) => state.global.profile);
  const partnerInput = useSelector((state) => state.global.partnerEmail);
  const promoCodeInput = useSelector((state) => state.global.promoCode);
  const testerMode = useSelector((state) => state.global.testerMode);

  const [dataObjects, setDataObjects] = useState(null);
  const [partners, setPartners] = useState(null);
  const [genCount, setGenCount] = useState(null);
  const [userCount, setUserCount] = useState(null);

  const product = 'Lonnlyst';
  const referalCode = `https://www.lonnlyst.no/referal?referal=${profile?._id}`;

  useEffect(() => {
    if (!profile) {
      startLogout();
    } else {
      getGenerations();
      if (profile.isAdmin) {
        getPartners();
        getGenCount();
        getUserCount();
      }
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [profile]);

  const startLogout = async () => {
    dispatch(setLogout({ token: null }));
    navigate('/');
  };

  const refreshCredits = async () => {
    const updatedProfile = await getUser(user.sub);
    dispatch(setProfile({ profile: updatedProfile }));
  };

  const getGenerations = async () => {
    const response = await fetch(`https://pixygon-server.onrender.com/aiusers/getGeneratedObjects/${profile._id}/${product}`);
    setDataObjects(await response.json());
  };

  const getPartners = async () => {
    const response = await fetch(`https://pixygon-server.onrender.com/aiusers/getPartners`);
    setPartners(await response.json());
  };

  const getGenCount = async () => {
    const response = await fetch(`https://pixygon-server.onrender.com/aiusers/getGeneratedObjectsCount/Lonnlyst`);
    setGenCount(await response.json());
  };

  const getUserCount = async () => {
    const response = await fetch(`https://pixygon-server.onrender.com/aiusers/getUserCount`);
    setUserCount(await response.json());
  };

  const refreshStats = async () => {
    setGenCount(null);
    setUserCount(null);
    getGenCount();
    getUserCount();
  };

  const handlePartnerInputChange = (e) => {
    dispatch(setPartnerEmail({ partnerEmail: e.target.value }));
  };

  const handlePromoCodeInputChange = (e) => {
    dispatch(setPromoCode({ promoCode: e.target.value }));
  };

  const addPromoter = async () => {
    await addPartner(partnerInput, promoCodeInput);
  };

  const handleTesterModeChange = () => {
    dispatch(setTesterMode({ testerMode: !testerMode }));
  };

  const PartnerList = () => (
    <Box width="100%">
      <Typography variant="h2" sx={{ mb: 2, color: '#2C1810' }}>Partnere</Typography>
      {partners ? (
        <CustomStack>
          {partners.map((partner, index) => (
            <PartnerObject
              key={partner._id}
              partner={partner}
              index={index}
              refresh={getPartners}
            />
          ))}
        </CustomStack>
      ) : (
        <CustomStack>
          {[...Array(4)].map((_, index) => (
            <Skeleton key={index} variant="rounded" animation="wave" height={80} />
          ))}
        </CustomStack>
      )}
    </Box>
  );

  const PartnerPanel = () => (
    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 3, mb: 3 }}>
      <Typography variant="h2" gutterBottom sx={{ color: '#2C1810' }}>Partner-panel</Typography>
      <Typography variant="body1" sx={{ color: '#4A4A4A' }}>
        Hvis folk bruker denne promokoden {profile.promoCode} når de kjøper mynter, 
        får du {profile.partnerCut}kr for hvert bildesalg, og bruker får {profile.partnerBonus}% ekstra bilde(r)! 
        Til nå har du solgt {profile.partnerTokensSold / 5} bilder! 
        Dvs du har tjent opp {(profile.partnerTokensSold / 5) * profile.partnerCut}kr!
      </Typography>
    </Box>
  );

  const DeveloperPanel = () => (
    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 3, mb: 3 }}>
      <FormControl component="fieldset">
        <FormLabel component="legend" sx={{ color: '#2C1810' }}>Galematias-modus</FormLabel>
        <FormControlLabel
          control={<Checkbox checked={testerMode} onChange={handleTesterModeChange} />}
          label="Ja takk"
          sx={{ color: '#4A4A4A' }}
        />
      </FormControl>
      <Typography variant="body1" sx={{ mt: 2, color: '#4A4A4A' }}>
        Galematias-modus er for de fryktløse, som liker å leve litt på kanten.
        Er det farlig? Så absolutt. Hva som helst kan skje hvis du har på
        galematias-modus, det kan bli feil, ting kan kræsje, og andre
        ulumskheter. Men er det gøy? Definitivt. (Det er bare utvikler-modus,
        slapp av)
      </Typography>
    </Box>
  );

  const StatsPanel = () => (
    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 3, mb: 3 }}>
      {genCount ? (
        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#2C1810' }}>
          Vi har redesignet {genCount} hager, fasader og rom så langt denne sesongen!
        </Typography>
      ) : (
        <Skeleton variant="text" height={40} width="100%" />
      )}
      {userCount ? (
        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#2C1810' }}>
          Vi har {userCount} brukere! Yowza!
        </Typography>
      ) : (
        <Skeleton variant="text" height={40} width="100%" />
      )}
      <CustomButton onClick={refreshStats}>Refresh</CustomButton>
    </Box>
  );

  const FriendReferalPanel = () => (
    <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, p: 3, mb: 3, textAlign: 'center' }}>
      <Typography variant="h2" gutterBottom sx={{ color: '#2C1810' }}>Inviter en venn</Typography>
      <Typography variant="body1" paragraph sx={{ color: '#4A4A4A' }}>
        Inviter en venn til lønnlyst, så får dere begge 20% rabatt på neste
        kjøp, og 3 ekstra genereringer. Send din venn denne linken, og be dem
        logge inn med vipps!
      </Typography>
      <Box 
        onClick={() => navigator.clipboard.writeText(referalCode)}
        sx={{ 
          backgroundColor: '#F5F5F1',
          borderRadius: 2,
          p: 2,
          cursor: 'pointer',
          '&:hover': { opacity: 0.8 }
        }}
      >
        <Typography variant="body1" sx={{ color: '#4A4A4A' }}>{referalCode}</Typography>
      </Box>
      {profile.invited && profile.invited.length > 0 && (
        <Typography variant="body1" sx={{ mt: 2, color: '#4A4A4A' }}>
          {profile.invited.length === 1 ? 'Du har invitert en venn!' : `Du har invitert ${profile.invited.length} venner!`}
        </Typography>
      )}
    </Box>
  );

  return (
    <Box sx={{
      minHeight: '100vh',
      backgroundColor: '#FAF9F7',
      py: 8,
    }}>
      <SEO
        title="LønnLyst - Din Profil"
        description="Administrer din LønnLyst-profil og se dine tilgjengelige genereringer."
        name="Lønnlyst - Din Profil"
        type="profile"
      />
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            fontWeight: 700,
            color: '#2C1810',
            mb: 4,
            mt: 20,
            textAlign: 'center',
          }}
        >
          Heisann {user.given_name}!
        </Typography>
        
        {profile.isAdmin && (
          <Accordion sx={{ mb: 3, backgroundColor: '#FFFFFF', borderRadius: 2 }}>
            <AccordionSummary expandIcon={<ArrowDownward />}>
              <Typography variant="h2" sx={{ color: '#2C1810' }}>Admin-panel</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1" paragraph sx={{ color: '#4A4A4A' }}>
                Heisann sveisan. Ser ut som du er admin! Da er du garantert
                kul. Her har du litt verktøy for å gjøre folk til promoters!
              </Typography>
              <StatsPanel />
              <Typography variant="h2" gutterBottom sx={{ color: '#2C1810' }}>Legg til ny partner</Typography>
              <CustomInputTextField
                label="Add Partner"
                onChange={handlePartnerInputChange}
                adornment={<AccountCircle />}
                placeholder=""
                tooltip="Har du noen ønsker?"
              />
              <CustomInputTextField
                label="Add Promo Code"
                onChange={handlePromoCodeInputChange}
                adornment={<AccountCircle />}
                placeholder=""
                tooltip="Har du noen ønsker?"
              />
              <CustomButton onClick={addPromoter}>Legg til partner</CustomButton>
              <PartnerList />
            </AccordionDetails>
          </Accordion>
        )}
        
        {profile.isPartner && <PartnerPanel />}
        
        <FriendReferalPanel />
        
        <Box sx={{ textAlign: 'center', mb: 3 }}>
          <Button onClick={refreshCredits} sx={{ mb: 2 }}>
            <Typography sx={{ color: '#4A4A4A' }}>Du har {Math.floor(profile.credits / 5)} genereringer igjen!</Typography>
          </Button>
          
          <CustomButton onClick={startLogout} sx={{ mb: 3 }}>Logg ut</CustomButton>
        </Box>
        
        <TokenPurchase />
        
        <DeveloperPanel />
      </Container>
    </Box>
  );
};