import { useState, useEffect } from 'react';
import { Box, Typography, Grid, Fade, useTheme } from '@mui/material';
import { CustomButton } from 'components/CustomButton';
import { CustomStack } from 'components/CustomStack';
import { SEO } from 'components/SEO';
import { LinkedIn, Email } from '@mui/icons-material';

export const AboutUs = () => {
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();

  const Person = ({ name, title, email, image, linkedin }) => {
    return (
      <Fade in={true} timeout={1000}>
        <Box
          sx={{
            position: 'relative',
            borderRadius: '24px',
            overflow: 'hidden',
            transition: 'all 0.3s ease',
            height: '400px',
            boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
            '&:hover': {
              transform: 'translateY(-8px)',
              boxShadow: '0 6px 25px rgba(0,0,0,0.15)',
              zIndex: 2,
            },
          }}
        >
          <Box
            component="img"
            src={image}
            alt={name}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              background: 'linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%)',
              padding: '2rem',
              color: 'white',
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: 600,
                mb: 0.5,
                color: 'white',
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                mb: 2,
                opacity: 0.9,
                color: 'white',
              }}
            >
              {title}
            </Typography>
            <CustomStack direction="row" spacing={1}>
              <CustomButton
                onClick={() => window.open(linkedin, '_blank')}
                aria-label={`${name}'s LinkedIn profile`}
                size="small"
                variant="contained"
                sx={{ 
                  minWidth: '40px',
                  minHeight: '40px',
                  backgroundColor: '#9B8B7E',
                  '&:hover': {
                    backgroundColor: '#8A7A6D',
                  }
                }}
              >
                <LinkedIn fontSize="small" />
              </CustomButton>
              <CustomButton
                onClick={() => window.location.href = `mailto:${email}`}
                aria-label={`Email ${name}`}
                size="small"
                variant="contained"
                sx={{ 
                  minWidth: '40px',
                  minHeight: '40px',
                  backgroundColor: '#9B8B7E',
                  '&:hover': {
                    backgroundColor: '#8A7A6D',
                  }
                }}
              >
                <Email fontSize="small" />
              </CustomButton>
            </CustomStack>
          </Box>
        </Box>
      </Fade>
    );
  };

  const getFile = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        'https://pixygontech.b-cdn.net/LL_PressKit.zip'
      );
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = "Lønnlyst_Presskit.zip";
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <SEO
        title="LønnLyst - Om oss"
        description="Lær mer om teamet bak LønnLyst og vår misjon om å skape innovative interiørdesignløsninger."
        name="LønnLyst - Om oss"
        type="website"
      />
      <Box 
        sx={{ 
          paddingTop: '120px',
          background: '#FAF9F7',
          minHeight: '100vh',
          color: '#2C2C2C',
        }}
      >
        <Box maxWidth="lg" margin="0 auto" padding={4}>
          <Typography 
            variant="h1" 
            component="h1" 
            sx={{ 
              fontSize: { xs: '2rem', sm: '2.5rem', md: '3.5rem' },
              fontWeight: 700,
              mb: 4,
              textAlign: 'center',
              color: '#2C2C2C',
            }}
          >
            Om oss
          </Typography>

          <Box 
            sx={{ 
              mb: 12, 
              textAlign: 'center', 
              maxWidth: '800px', 
              mx: 'auto',
              backgroundColor: 'rgba(255,255,255,0.6)',
              borderRadius: '20px',
              padding: '2rem',
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
            }}
          >
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '1.1rem',
                mb: 4,
                color: '#4A4A4A',
              }}
            >
              LønnLyst er en webtjeneste utviklet av Pixygon.
            </Typography>
            <Box 
              sx={{
                backgroundColor: '#9B8B7E',
                borderRadius: '20px',
                p: 3,
                mb: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                transition: 'transform 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.02)',
                },
              }}
            >
              <Box
                component="img"
                src="https://pixygontech.b-cdn.net/PixygonLogoWide.png"
                alt="Pixygon Logo"
                sx={{
                  width: '90%',
                  maxWidth: '300px',
                  cursor: 'pointer',
                }}
                onClick={() => window.open("https://www.pixygon.io", "_blank")}
              />
            </Box>
            <Typography 
              variant="body1" 
              sx={{ 
                fontSize: '1.1rem',
                color: '#4A4A4A',
              }}
            >
              For henvendelser, kontakt oss gjerne på{' '}
              <Box
                component="a"
                href="mailto:pixygon@pixygon.io"
                sx={{ 
                  color: '#9B8B7E',
                  textDecoration: 'none',
                  fontWeight: 600,
                  '&:hover': {
                    textDecoration: 'underline',
                  }
                }}
              >
                pixygon@pixygon.io
              </Box>
            </Typography>
          </Box>

          <Box sx={{ mb: 12 }}>
            <Typography 
              variant="h2" 
              sx={{ 
                fontSize: { xs: '1.5rem', sm: '2rem', md: '2.5rem' },
                fontWeight: 600,
                mb: 4,
                textAlign: 'center',
                color: '#2C2C2C',
              }}
            >
              Teamet
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6} md={4}>
                <Person
                  name="Jo Jørgen Stordal"
                  title="Daglig Leder"
                  email="jo@pixygon.io"
                  image="https://pixygontech.b-cdn.net/1679692090087.jpg"
                  linkedin="https://www.linkedin.com/in/jjs-60904236/"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Person
                  name="Anders Oneiros"
                  title="Kodemannen"
                  email="anders@pixygon.io"
                  image="https://pixygontech.b-cdn.net/1669775093138.jpg"
                  linkedin="https://www.linkedin.com/in/andershorne/"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Person
                  name="John Weaver"
                  title="Grafikkmannen"
                  email="john@pixygon.io"
                  image="https://pixygontech.b-cdn.net/1671622964304.jpg"
                  linkedin="https://www.linkedin.com/in/john-weaver-912841226/"
                />
              </Grid>
            </Grid>
          </Box>

          <Box
            sx={{
              backgroundColor: 'rgba(255,255,255,0.6)',
              borderRadius: '20px',
              p: 4,
              mb: 8,
              textAlign: 'center',
              maxWidth: '500px',
              mx: 'auto',
              boxShadow: '0 4px 20px rgba(0,0,0,0.05)',
            }}
          >
            <Typography 
              variant="h2" 
              sx={{ 
                fontSize: { xs: '1.5rem', sm: '2rem' },
                fontWeight: 600,
                mb: 2,
                color: '#2C2C2C',
              }}
            >
              Presse
            </Typography>
            <Typography sx={{ color: '#4A4A4A', mb: 3 }} paragraph>
              Last ned en .zip med logoer og annet materiell!
            </Typography>
            <CustomButton 
              onClick={getFile} 
              disabled={isLoading}
              size="large"
              sx={{
                backgroundColor: '#9B8B7E',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#8A7A6D',
                },
                '&:disabled': {
                  backgroundColor: '#C0B5AE',
                  color: 'rgba(255,255,255,0.7)',
                },
              }}
            >
              {isLoading ? 'Laster ned...' : 'Last ned'}
            </CustomButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};