import { Box, Typography, Button, Card, CardContent, useTheme, Stack, alpha, useMediaQuery } from '@mui/material';
import { useSelector, useDispatch} from 'react-redux';
import { setProduct, setTransaction, setPromoCode } from '../state';
import { useNavigate } from 'react-router-dom';
import { startPayment, verifyPromoCode } from './ApiActions';
import { CustomInputTextField } from './CustomInputTextField';
import { AccountCircle, Check, ArrowForward } from '@mui/icons-material';
import { useState } from 'react';

export const TokenPurchase = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isNonMobile = useMediaQuery('(min-width: 1000px)');
  const navigate = useNavigate();
  const phoneNumber = useSelector((state) => state.global.phoneNumber);
  const profile = useSelector((state) => state.global.profile);
  const [isVerified, setIsVerified] = useState(false);
  const [hasClicked, setHasClicked] = useState(false);

  const doPayment = (product) => {
    if (hasClicked) return;
    setHasClicked(true);
    const idempotencyKey = crypto.randomUUID();
    const reference = createString(50);
    dispatch(setTransaction({ idempotencyKey, reference }));
    dispatch(setProduct({ product }));
    const useOffer = false;
    startPayment({ idempotencyKey, reference, phoneNumber, product, useOffer });
  };

  const SetPromoterCode = (event) => {
    dispatch(setPromoCode({ promoCode: event.target.value }));
    startVerifyPromoCode(event.target.value);
  };

  const createString = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-';
    return Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  };

  const startVerifyPromoCode = async (code) => {
    setIsVerified(false);
    setIsVerified(await verifyPromoCode(code));
  };

  const TokenPurchaseBox = ({ amount, price, type, packname, packText, target = '', hasOffer = false }) => {
    const finalPrice = hasOffer ? price - Math.floor(price * 0.2) : price;

    return (
      <Card
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '20px',
          boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
          overflow: 'hidden',
          transition: 'all 0.3s ease-in-out',
          '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0 10px 30px rgba(0,0,0,0.1)',
          },
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardContent sx={{ p: 4, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ mb: 3 }}>
            {target && (
              <Typography
                variant="overline"
                sx={{
                  color: '#2C1810',
                  fontWeight: 600,
                  mb: 1,
                  display: 'inline-block',
                  backgroundColor: alpha('#2C1810', 0.05),
                  px: 1,
                  py: 0.5,
                  borderRadius: '4px',
                }}
              >
                {target}
              </Typography>
            )}
            <Typography variant="h5" fontWeight="bold" color="#2C1810" mb={1}>
              {packname}
            </Typography>
            <Stack direction="row" alignItems="baseline" spacing={1}>
              <Typography variant="h3" fontWeight="bold" color="#2C1810">
                {finalPrice}kr
              </Typography>
              <Typography variant="body2" color="#4A4A4A">
                /{amount} bilder
              </Typography>
            </Stack>
          </Box>
          <Typography variant="body2" color="#4A4A4A" mb={4} sx={{ flexGrow: 1 }}>
            {packText}
          </Typography>
          <Box onClick={() => doPayment(type)}>
            <vipps-mobilepay-button
              type="button"
              brand="vipps"
              language="no"
              variant="primary"
              rounded="false"
              verb="pay"
              stretched="true"
              branded="true"
              loading={hasClicked ? 'true' : 'false'}
            />
          </Box>
        </CardContent>
      </Card>
    );
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '1200px', mx: 'auto' }}>
      <Box
        sx={{
          backgroundColor: '#FFFFFF',
          borderRadius: '20px',
          p: 4,
          mb: 6,
          boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
        }}
      >
        <CustomInputTextField
          key="promoCodeInput"
          label="Promo Kode"
          onChange={SetPromoterCode}
          adornment={<AccountCircle />}
          placeholder=""
          tooltip="Har du noen ønsker?"
        />
        {isVerified && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, color: '#2C1810' }}>
            <Check sx={{ mr: 1 }} />
            <Typography>Denne promokoden fungerer!</Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr', lg: 'repeat(4, 1fr)' },
          gap: 4,
        }}
      >
        {profile.isAdmin && (
          <TokenPurchaseBox
            amount={2}
            price="1"
            type="10 Mynter"
            packname="Juksepakka"
            target="For admins!"
            packText="Denne er sjukt bra. Den beste vil mange si. Helt vilt"
            hasOffer={true}
          />
        )}
        <TokenPurchaseBox
          amount={10}
          price="79"
          type="50 Mynter"
          packname="Startpakken"
          packText="Start nå! For kun 79 kroner får du 10 bildegenereringer for å forvandle dine rom. Perfekt for å teste ut tjenesten!"
          target="Start her!"
        />
        <TokenPurchaseBox
          amount={20}
          price="129"
          type="100 Mynter"
          packname="Standardpakken"
          packText="Ta steget videre! Få 20 bildegenereringer for 129 kroner. Utforsk og finn din stil med våre AI-verktøy."
          target="Mest populær!"
        />
        <TokenPurchaseBox
          amount={40}
          price="239"
          type="200 Mynter"
          packname="Plusspakken"
          packText="For de som ønsker mer: 40 bildegenereringer for 239 kroner. Perfekt for større prosjekter eller flere rom."
        />
        <TokenPurchaseBox
          amount={80}
          price="398"
          type="400 Mynter"
          packname="Premium"
          packText="For den ultimate opplevelsen: 80 bildegenereringer for 398 kroner. Dette er pakken for interiørdesignere og profesjonelle."
          target="For designere!"
        />
      </Box>
    </Box>
  );
};