import { useState } from 'react';
import { 
  Box, 
  Typography, 
  Tabs, 
  Tab, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText,
  Collapse,
  Link,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { SEO } from 'components/SEO';
import { CustomButton } from 'components/CustomButton';
import {
  Home,
  Lock,
  AccessTime,
  Save,
  CreditCard,
  Email,
  HelpOutline,
  PlayCircleOutline,
  ExpandMore
} from '@mui/icons-material';

const StyledListItem = styled(ListItem)(({ theme }) => ({
  borderRadius: '12px',
  border: '1px solid #F0EDE8',
  marginBottom: '8px',
  backgroundColor: '#FFFFFF',
  flexDirection: 'column',
  alignItems: 'flex-start',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#FAF9F7',
  },
}));

const VideoCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  borderRadius: '16px',
  padding: '24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  border: '1px solid #F0EDE8',
}));

const ExpandIcon = styled(ExpandMore)(({ theme, expanded }) => ({
  transition: 'transform 0.3s ease-in-out',
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
}));

const StyledCustomButton = styled(CustomButton)(({ theme }) => ({
  backgroundColor: '#9B8B7E',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#8A7A6D',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '& .MuiTab-root': {
    borderRadius: '100px',
    border: '1px solid #F0EDE8',
    marginRight: '8px',
    color: '#6B6B6B',
    minWidth: 'auto',
    padding: '6px 12px',
    '&.Mui-selected': {
      backgroundColor: '#9B8B7E',
      color: '#FFFFFF'
    }
  },
  '& .MuiTabs-indicator': {
    display: 'none',
  },
}));

const categories = [
  { label: 'General', value: 'general' },
  { label: 'Priser', value: 'pricing' },
  { label: 'Bruk', value: 'usage' },
  { label: 'Support', value: 'support' }
];

const faqItems = {
  general: [
    {
      icon: <Home />,
      question: 'Hva er LønnLyst?',
      answer: 'LønnLyst er en kunstig intelligens tjeneste som lar deg ta bilder av dine rom og øyeblikkelig se hvordan de vil kunne se ut i ulike stilarter og interiørdesign. Med avansert AI-teknologi fjernes eksisterende interiør for å gi plass til nye, inspirerende forslag.'
    },
    {
      icon: <Lock />,
      question: 'Er mine bilder private?',
      answer: 'Absolutt, dine bilder forblir helt private. Kun du har tilgang til dem, og de deles ikke med andre eller med noen hos LønnLyst. Orginalbildene du tar av dine egne rom, fasader eller hage lagres ikke, disse slettes umiddelbart.'
    },
    {
      icon: <AccessTime />,
      question: 'Hvor raskt lages et bilde?',
      answer: 'Dine bilder genereres umiddelbart og er klare for visning i løpet av 10 - 30 sekunder.'
    },
    {
      icon: <Save />,
      question: 'Kan jeg lagre de ferdige bildene?',
      answer: 'Selvfølgelig kan du lagre bildene på din telefon eller datamaskin. Dette er ideelt for planleggingsfasen av oppussing, renovering, eller når du skal diskutere ideer med håndverkere.'
    }
  ],
  pricing: [
    {
      icon: <CreditCard />,
      question: 'Hvor mye koster det å bruke LønnLyst?',
      answer: 'De to første bildene er gratis. Deretter, fra kun 79 kr får du tilgang til å utføre fra 10 redesign av dine rom, eksteriør eller hage. Trenger du flere redesign? Kjøp enkelt ekstra når som helst og fortsett å transformere dine rom etter behov.'
    },
    {
      icon: <CreditCard />,
      question: 'Hvilke betalingsmetoder aksepterer LønnLyst?',
      answer: 'Vi tilbyr en sikker og pålitelig betalingserfaring gjennom vår partner Vipps, slik at du enkelt kan betale for tjenesten.'
    }
  ],
  usage: [
    {
      icon: <HelpOutline />,
      question: 'Hva skjer når jeg har brukt opp mine bildegenereringer?',
      answer: 'Da kan du kjøpe nye ved bruk av Vipps. Dette lar deg bruke tjenesten fleksibelt, uten behov for et løpende abonnement.'
    }
  ],
  support: [
    {
      icon: <Email />,
      question: 'Jeg trenger support, hvor henvender jeg meg?',
      answer: 'Start gjerne med å ta kontakt med oss på jo@pixygon.io.'
    }
  ]
};

export const Faq = () => {
  const [category, setCategory] = useState('general');
  const [expanded, setExpanded] = useState(null);

  const handleCategoryChange = (event, newValue) => {
    setCategory(newValue);
    setExpanded(null);
  };

  const handleExpandItem = (index) => {
    setExpanded(expanded === index ? null : index);
  };

  return (
    <Box sx={{ 
      backgroundColor: '#FAF9F7',
      padding: '120px 24px 64px',
      minHeight: '100vh'
    }}>
      <SEO
        title="LønnLyst - Ofte Stile Spørsmål"
        description="Alle dine spørsmål om LønnLyst, besvart!"
        name="Lønnlyst - Ofte Stilte Spørsmål"
        type="tool"
      />

      <Box sx={{ maxWidth: '800px', margin: '0 auto' }}>
        <Typography 
          variant="h4" 
          component="h1" 
          sx={{ 
            mb: 1, 
            fontWeight: 600,
            color: '#2C2C2C'
          }}
        >
          Ofte Stilte Spørsmål
        </Typography>
        
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 4,
            color: '#6B6B6B'
          }}
        >
          Disse er de vanligste spørsmålene om LønnLyst.
          Finner du ikke det du leter etter?{' '}
          <Link 
            href="mailto:jo@pixygon.io" 
            sx={{ 
              color: '#9B8B7E',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'underline'
              }
            }}
          >
            Chat med vårt vennlige team!
          </Link>
        </Typography>

        <Grid container spacing={2} sx={{ mb: 6 }}>
          <Grid item xs={12} sm={4}>
            <VideoCard>
              <PlayCircleOutline sx={{ fontSize: 32, color: '#9B8B7E' }} />
              <Typography variant="subtitle1" sx={{ color: '#2C2C2C' }}>
                Interiør
              </Typography>
              <StyledCustomButton onClick={() => window.open('https://youtu.be/FXDRK5NrYyk?si=BqAP_B-CKSwzgGtX', '_blank')}>
                Se video
              </StyledCustomButton>
            </VideoCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <VideoCard>
              <PlayCircleOutline sx={{ fontSize: 32, color: '#9B8B7E' }} />
              <Typography variant="subtitle1" sx={{ color: '#2C2C2C' }}>
                Hage
              </Typography>
              <StyledCustomButton onClick={() => window.open('https://youtu.be/KSc-jOPEFU0?si=3MFSPrszVM_K3bZq', '_blank')}>
                Se video
              </StyledCustomButton>
            </VideoCard>
          </Grid>
          <Grid item xs={12} sm={4}>
            <VideoCard>
              <PlayCircleOutline sx={{ fontSize: 32, color: '#9B8B7E' }} />
              <Typography variant="subtitle1" sx={{ color: '#2C2C2C' }}>
                Eksteriør
              </Typography>
              <StyledCustomButton onClick={() => window.open('https://youtu.be/-m1em4hWA0Y?si=vFL5Dt5B4V2PI5Nx', '_blank')}>
                Se video
              </StyledCustomButton>
            </VideoCard>
          </Grid>
        </Grid>

        <StyledTabs 
          value={category} 
          onChange={handleCategoryChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ mb: 4 }}
        >
          {categories.map((cat) => (
            <Tab
              key={cat.value}
              label={cat.label}
              value={cat.value}
            />
          ))}
        </StyledTabs>

        <List sx={{ 
          display: 'flex', 
          flexDirection: 'column',
          gap: 1
        }}>
          {faqItems[category]?.map((item, index) => (
            <StyledListItem
              key={index}
              button
              onClick={() => handleExpandItem(index)}
            >
              <Box sx={{ 
                display: 'flex', 
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <ListItemIcon sx={{ 
                    minWidth: 40,
                    color: '#9B8B7E'
                  }}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText 
                    primary={item.question}
                    primaryTypographyProps={{
                      sx: {
                        fontWeight: 500,
                        color: '#2C2C2C'
                      }
                    }}
                  />
                </Box>
                <ExpandIcon expanded={expanded === index} />
              </Box>
              <Collapse in={expanded === index} timeout="auto" unmountOnExit sx={{ width: '100%' }}>
                <Box sx={{ py: 2 }}>
                  <Typography sx={{ color: '#6B6B6B' }}>
                    {item.answer}
                  </Typography>
                </Box>
              </Collapse>
            </StyledListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};