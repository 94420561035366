import { Box, Stack, Typography, Grid, Container, styled } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { useDispatch } from 'react-redux';
import { startLogin } from 'components/ApiActions';

const StyledButtonWrapper = styled(Box)(({ theme }) => ({
  display: 'inline-block',
  position: 'relative',
  borderRadius: '50px',
  padding: '6px 24px',
  fontSize: '1rem',
  fontWeight: 500,
  backgroundColor: '#8B7355',
  color: 'white',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    backgroundColor: '#6A5642',
  },
  '& vipps-mobilepay-button': {
    opacity: 0,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    cursor: 'pointer',
  },
}));

export const AIDesignSection = ({ data }) => {
  const dispatch = useDispatch();

  const doStartLogin = () => {
    dispatch(startLogin());
  };

  return (
    <Container maxWidth="lg" sx={{ my: 12 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={7}> 
          <Box
            sx={{
              borderRadius: '24px',
              overflow: 'hidden',
              position: 'relative',
              paddingTop: '75%',
            }}
          >
            <Box
              component="img"
              src={data.image}
              alt="AI-generert Interiørdesign"
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                bgcolor: 'rgba(245, 245, 245, 0.95)',
                padding: '18px',
                width: '75%',
                borderTopRightRadius: '24px',
              }}
            >
              <Typography 
                variant="overline" 
                sx={{ 
                  color: '#6B5B45', 
                  letterSpacing: 3,
                  fontSize: '0.75rem'
                }}
              >
                {data.subtitle}
              </Typography>
              <Typography 
                variant="h3" 
                sx={{ 
                  color: '#2C1810', 
                  fontWeight: 600,
                  fontSize: '2rem',
                  mb: 1,
                  letterSpacing: '-0.02em'
                }}
              >
                {data.title}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: '#4A4A4A',
                  lineHeight: 1.6
                }}
              >
                {data.mainText}
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Stack spacing={3} height="100%">
            <Box
              sx={{
                bgcolor: '#F5F5F1',
                borderRadius: '24px',
                p: 4,
                flex: '0 0 45%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography 
                variant="overline" 
                sx={{ 
                  color: '#6B5B45', 
                  letterSpacing: 3,
                  fontSize: '0.75rem'
                }}
              >
                {data.innovativeTechnologyTitle}
              </Typography>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 600,
                  mb: 2,
                  color: '#2C1810',
                  fontSize: '1.75rem',
                  letterSpacing: '-0.02em'
                }}
              >
                {data.createDreamHomeTitle}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: '#4A4A4A',
                  lineHeight: 1.6
                }}
              >
                {data.description}
              </Typography>
            </Box>
            <Box
              sx={{
                borderRadius: '24px',
                overflow: 'hidden',
                position: 'relative',
                flex: '0 0 52%',
                paddingTop: '52%',
              }}
            >
              <Box
                component="img"
                src={data.secondaryImage}
                alt="Skandinavisk interiør"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  bgcolor: 'rgba(245, 245, 245, 0.95)',
                  padding: '16px 24px',
                }}
              >
                <Typography 
                  variant="body2"
                  sx={{ 
                    color: '#4A4A4A',
                    fontWeight: 500,
                  }}
                >
                  {data.experienceEleganceText}
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 16 }}>
        <Typography 
          variant="body1" 
          sx={{ 
            mr: 6, 
            color: '#4A4A4A', 
            maxWidth: '600px', 
            textAlign: 'right',
            fontSize: '1.35rem',
          }}
        >
          {data.leftButtonText}
        </Typography>
        <StyledButtonWrapper onClick={doStartLogin}>
          <Box sx={{ display: 'inline-flex', mr: 1, verticalAlign: 'middle' }}>
            <AutoAwesomeIcon />
          </Box>
          {data.tryAIButtonText}
          <vipps-mobilepay-button
            type="button"
            brand="vipps"
            language="no"
            variant="primary"
            rounded="true"
            verb="login"
            stretched="false"
            branded="true"
            loading="false"
          />
        </StyledButtonWrapper>
      </Box>
    </Container>
  );
};