import { Box, Typography, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { startLogin } from 'components/ApiActions';


const StyledText = ({ text }) => {
  const parts = text.split(/(\*\*.*?\*\*)/g);
  return parts.map((part, index) => {
    if (part.startsWith('**') && part.endsWith('**')) {
      return (
        <Box
          key={index}
          component="span"
          sx={{
            fontSize: '1.2rem',
            fontWeight: 700,
            color: '#FFFFFF',
            textTransform: 'uppercase',
          }}
        >
          {part.slice(2, -2)}
        </Box>
      );
    }
    return <Box component="span" key={index}>{part}</Box>;
  });
};

export const CTA = ({ data }) => {
  const dispatch = useDispatch();

  if (!data) {
    return null;
  }

  const doStartLogin = () => {
    dispatch(startLogin());
  };

  return (
    <Box sx={{ bgcolor: '#8B7355', py: 12 }}>
      <Container maxWidth="md" sx={{ textAlign: 'center' }}>
        <Typography variant="h2" sx={{ fontWeight: 800, mb: 4, color: '#F5F5F1', fontSize: { xs: '2.5rem', md: '3.5rem' } }}>
          {data.title}
        </Typography>
        <Typography variant="body1" sx={{ mb: 6, fontSize: '1.1rem', lineHeight: 1.6, color: '#F5F5F1' }}>
          <StyledText text={data.description} />
        </Typography>
        <Box onClick={doStartLogin} sx={{ display: 'inline-block' }}>
          <vipps-mobilepay-button
            type="button"
            brand="vipps"
            language="no"
            variant="primary"
            rounded="true"
            verb="login"
            stretched="false"
            branded="true"
            loading="false"
          />
        </Box>
      </Container>
    </Box>
  );
};