import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
  Box, 
  Typography, 
  Skeleton, 
  FormControl, 
  FormControlLabel, 
  Checkbox,
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { setLogout } from '../state';
import { GeneratedObject } from 'components/GeneratedObject';
import { SEO } from 'components/SEO';

export const MyImages = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery('(min-width: 1000px)');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dataObjects, setDataObjects] = useState(null);
  const [sortByLiked, setSortByLiked] = useState(false);
  const [fetchingObjects, setFetchingObjects] = useState(false);
  const profile = useSelector((state) => state.global.profile);
  const product = 'Lonnlyst';

  const startLogout = async () => {
    dispatch(setLogout({ token: null }));
    navigate('/');
  };

  const handleSortChange = (event) => {
    if (fetchingObjects) return;
    setSortByLiked(!sortByLiked);
    getGenerations();
  };

  const getGenerations = async () => {
    setFetchingObjects(true);
    const url = sortByLiked
      ? `https://pixygon-server.onrender.com/aiusers/getLikedGeneratedObjects/${profile._id}/${product}`
      : `https://pixygon-server.onrender.com/aiusers/getGeneratedObjects/${profile._id}/${product}`;
    
    const infoResponse = await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    setDataObjects(await infoResponse.json());
    setFetchingObjects(false);
  };

  useEffect(() => {
    if (!profile) {
      startLogout();
    }
    getGenerations();
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Box sx={{
      minHeight: '100vh',
      backgroundColor: '#FAF9F7',
      py: 8,
    }}>
      <SEO
        title="LønnLyst - Dine Bilder"
        description="Se og administrer dine genererte bilder fra LønnLyst."
        name="Lønnlyst - Dine Bilder"
        type="profile"
      />
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: '2.5rem', md: '3.5rem' },
            fontWeight: 700,
            color: '#2C1810',
            mb: 4,
            mt: 20,
            textAlign: 'center',
          }}
        >
          Dine bilder
        </Typography>

        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '20px',
            p: 4,
            mb: 6,
            boxShadow: '0 4px 20px rgba(0,0,0,0.06)',
          }}
        >
          <FormControl component="fieldset">
            <FormControlLabel
              control={
                <Checkbox
                  checked={sortByLiked}
                  onChange={handleSortChange}
                  sx={{
                    color: '#2C1810',
                    '&.Mui-checked': {
                      color: '#2C1810',
                    },
                  }}
                />
              }
              label={
                <Typography sx={{ color: '#4A4A4A' }}>
                  Bare vis favoritter
                </Typography>
              }
            />
          </FormControl>
        </Box>

        {dataObjects ? (
          <Grid
            container
            spacing={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            {dataObjects.map((dataObject, index) => (
              <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                <GeneratedObject
                  dataObject={dataObject}
                  index={index}
                  refetch={getGenerations}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            spacing={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'stretch',
            }}
          >
            {[...Array(8)].map((_, index) => (
              <Grid key={index} xs={12} sm={6} md={4} lg={3}>
                <Skeleton
                  variant="rounded"
                  animation="wave"
                  height={300}
                  sx={{ borderRadius: '20px' }}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};