import React, { useState, useEffect } from "react";
import {
  Box,
  Container,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Link,
  useTheme,
  useMediaQuery,
  Button,
  Stack,
} from "@mui/material";
import { AccountCircle, ArrowDownward, AutoAwesome, ShoppingCart } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getUser, payCredits, addGeneratedObject } from "./ApiActions";
import { ToggleButtons } from "./ToggleButtons";
import { ToggleButtonGrid } from "./ToggleButtonGrid";
import {
  finalizeTransaction,
  setType,
  setPrompt,
  setProfile,
  setFinalizedImage,
  setIsWriting,
  setIsFinished,
  setErrorMessage,
  setIsAborted,
  setRoom,
  setRedesignMode,
  setColor,
  setMaterial,
  setMaterialType,
  setDesignStyle,
} from "../state";
import { CameraCapture } from "./CameraCapture";
import {
  beautifulRedesign,
  removeFurniture,
  changeColorTexture,
  decorStaging,
  creativeRedesign,
  newBeautifulRedesign,
  inpainting,
  sketchToRender,
} from "./HomedesignApi";
import { SliderField } from "./SliderField";
import { CustomInputTextField } from "./CustomInputTextField";
import { DropdownSelect } from "./DropdownSelect";
import {
  styleObjects,
  styleExteriorObjects,
  styleGardenObjects,
  roomObjects,
  roomExteriorObjects,
  roomGardenObjects,
  colorObjects,
  materialObjects,
  materialExteriorObjects,
  materialGardenObjects,
} from "./Styles";
import { CustomButton } from "./CustomButton";
import ReactGA from "react-ga4";
import { SEO } from "./SEO";

export const GenerationScreen = () => {
  const theme = useTheme();
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    type,
    room,
    designStyles,
    color,
    material,
    profile,
    image,
    actualImage,
    errorMessage,
    isAborted,
    redesignMode,
    prompt,
    hasDrawn,
    actualMaskImage,
    maskedImage,
    testerMode,
  } = useSelector((state) => state.global);
  const user = useSelector((state) => state.global.user);

  const [imagesToGenerate, setImagesToGenerate] = useState(1);
  const [aiIntervention, setAiIntervention] = useState("Mid");
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    getProfile();
    dispatch(setRedesignMode({ redesignMode: 0 }));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const getProfile = async () => {
    const profile = await getUser(user.sub);
    dispatch(setProfile({ profile }));
  };

  const handleRedesignChange = (value) => {
    dispatch(setRedesignMode({ redesignMode: value }));
    resetOptions();
  };

  const handleRequestTypeChange = (newType) => {
    dispatch(setType({ type: newType }));
    resetOptions();
  };

  const resetOptions = () => {
    dispatch(setRoom({ room: 1 }));
    dispatch(setIsAborted({ isAborted: false }));
    dispatch(setDesignStyle({ designStyles: getRandomStyle(type) }));
    dispatch(setMaterial({ material: 1 }));
  };

  const getRandomStyle = (newType) => {
    switch (newType) {
      case "Interior":
        return Math.floor(Math.random() * styleObjects.length);
      case "Exterior":
        return Math.floor(Math.random() * styleExteriorObjects.length);
      case "Garden":
        return Math.floor(Math.random() * styleGardenObjects.length);
      default:
        return 0;
    }
  };

  const createRedesign = async () => {
    ReactGA.event({
      category: "Generation",
      action: "Started generation",
    });
    dispatch(setIsAborted({ isAborted: false }));
    dispatch(setIsWriting({ isWriting: true }));
    dispatch(setIsFinished({ isFinished: false }));

    try {
      let returnedImage = null;
      switch (redesignMode) {
        case 0:
          returnedImage = await beautifulRedesign(
            actualImage,
            type,
            getRoom(),
            getStyle(),
            aiIntervention,
            imagesToGenerate,
            prompt
          );
          break;
        case 1:
          returnedImage = await creativeRedesign(
            actualImage,
            type,
            getRoom(),
            getStyle(),
            aiIntervention,
            imagesToGenerate,
            prompt,
            false
          );
          break;
        case 2:
          if (actualMaskImage != null) {
            returnedImage = await inpainting(
              actualImage,
              actualMaskImage,
              type,
              imagesToGenerate,
              getStyle(),
              getRoom(),
              prompt
            );
          } else {
            returnedImage = await creativeRedesign(
              actualImage,
              type,
              getRoom(),
              getStyle(),
              aiIntervention,
              imagesToGenerate,
              prompt,
              true
            );
          }
          break;
        case 3:
          returnedImage = await removeFurniture(
            actualImage,
            actualMaskImage,
            imagesToGenerate
          );
          break;
        case 4:
          returnedImage = await changeColorTexture(
            actualImage,
            actualMaskImage,
            type,
            imagesToGenerate,
            prompt,
            colorObjects[color].label,
            "",
            ""
          );
          break;
        case 5:
          returnedImage = await decorStaging(
            actualImage,
            actualMaskImage,
            type,
            imagesToGenerate,
            getStyle(),
            getRoom(),
            prompt,
            false
          );
          break;
        case 7:
          returnedImage = await changeColorTexture(
            actualImage,
            actualMaskImage,
            type,
            imagesToGenerate,
            prompt,
            "null",
            getMaterialType(),
            getMaterial()
          );
          break;
        case 8:
          returnedImage = await sketchToRender(
            actualImage,
            type,
            getRoom(),
            getStyle(),
            aiIntervention,
            imagesToGenerate,
            prompt
          );
          break;
        default:
          throw new Error("Invalid redesign mode");
      }

      if (isAborted) {
        ReactGA.event({
          category: "Generation",
          action: "Aborted generation",
        });
        dispatch(setIsAborted({ isAborted: false }));
        dispatch(setIsWriting({ isWriting: false }));
        dispatch(setIsFinished({ isFinished: false }));
      } else {
        const payment = await payCredits(user.sub, 5);
        if (payment.message !== "success!") {
          throw new Error("Payment failed");
        }

        const response = await finalizeTextTransaction(
          [type, getRoom(), getStyle(), aiIntervention, prompt],
          "",
          returnedImage.success.generated_image[0]
        );

        dispatch(setFinalizedImage({ image: response }));
        dispatch(finalizeTransaction());
        dispatch(setIsAborted({ isAborted: false }));
        dispatch(setIsWriting({ isWriting: false }));
        dispatch(setIsFinished({ isFinished: true }));

        ReactGA.event({
          category: "Generation",
          action: "Completed generation",
        });
      }
    } catch (error) {
      console.error("Error in createRedesign:", error);
      dispatch(setErrorMessage({ errorMessage: error.message }));
      dispatch(setIsAborted({ isAborted: false }));
      dispatch(setIsWriting({ isWriting: false }));
      dispatch(setIsFinished({ isFinished: false }));

      ReactGA.event({
        category: "Generation",
        action: "Failed generation",
      });
    }
  };

  const finalizeTextTransaction = async (p, s, i) => {
    const profile = await getUser(user.sub);
    dispatch(setProfile({ profile }));
    return await addGeneratedObject(profile._id, 5, p, s, i);
  };

  const getRoom = () => {
    switch (type) {
      case "Interior":
        return roomObjects[room].label;
      case "Exterior":
        return roomExteriorObjects[room].label;
      case "Garden":
        return roomGardenObjects[room].label;
    }
  };

  const getStyle = () => {
    switch (type) {
      case "Interior":
        return styleObjects[designStyles].label;
      case "Exterior":
        return styleExteriorObjects[designStyles].label;
      case "Garden":
        return styleGardenObjects[designStyles].label;
    }
  };

  const getMaterialType = () => {
    switch (type) {
      case "Interior":
        return materialObjects[material].label.split("|")[0];
      case "Exterior":
        return materialExteriorObjects[material].label.split("|")[0];
      case "Garden":
        return materialGardenObjects[material].label.split("|")[0];
    }
  };

  const getMaterial = () => {
    switch (type) {
      case "Interior":
        return materialObjects[material].label.split("|")[1];
      case "Exterior":
        return materialExteriorObjects[material].label.split("|")[1];
      case "Garden":
        return materialGardenObjects[material].label.split("|")[1];
    }
  };

  const StyleDropdowns = () => {
    const handleRoomChange = (event) => {
      dispatch(setRoom({ room: event.target.value }));
    };

    const handleStyleChange = (event) => {
      dispatch(setDesignStyle({ designStyles: event.target.value }));
    };

    const handleColorChange = (event) => {
      dispatch(setColor({ color: event.target.value }));
    };

    const handleMaterialChange = (event) => {
      dispatch(setMaterial({ material: event.target.value }));
    };

    if (redesignMode === 3) {
      return null;
    } else if (redesignMode === 4) {
      return (
        <DropdownSelect
          item="Farge"
          current={color}
          handleChange={handleColorChange}
          styleObjects={colorObjects}
        />
      );
    } else if (redesignMode === 7) {
      const materialOptions = type === "Interior" ? materialObjects : 
                              type === "Exterior" ? materialExteriorObjects : 
                              materialGardenObjects;
      return (
        <DropdownSelect
          item="Materiale"
          current={material}
          handleChange={handleMaterialChange}
          styleObjects={materialOptions}
        />
      );
    } else {
      const roomOptions = type === "Interior" ? roomObjects : 
                          type === "Exterior" ? roomExteriorObjects : 
                          roomGardenObjects;
      const styleOptions = type === "Interior" ? styleObjects : 
                           type === "Exterior" ? styleExteriorObjects : 
                           styleGardenObjects;
      return (
        <>
          <DropdownSelect
            item="Rom"
            current={room}
            handleChange={handleRoomChange}
            styleObjects={roomOptions}
          />
          <DropdownSelect
            item="Stil"
            current={designStyles}
            handleChange={handleStyleChange}
            styleObjects={styleOptions}
          />
        </>
      );
    }
  };

  const ExtraDesignOptions = () => {
    return (
      <Accordion
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "20px",
          boxShadow: "0 4px 20px rgba(0,0,0,0.06)",
          mb: 3,
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ArrowDownward />}
          sx={{
            "& .MuiAccordionSummary-content": {
              justifyContent: "center",
            },
          }}
        >
          <Typography variant="h6" color="#2C1810">
            Avansert
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SliderField
            label="Antall Bilder"
            min={1}
            max={4}
            defaultValue={1}
            marks={null}
            onChange={(value) => setImagesToGenerate(value)}
          />
          <SliderField
            label="AI Kontroll"
            min={0}
            max={3}
            defaultValue={2}
            marks={[
              { value: 0, label: "Veldig lav" },
              { value: 1, label: "Lav" },
              { value: 2, label: "Medium" },
              { value: 3, label: "Høy" },
            ]}
            onChange={(value) => {
              const levels = ["Veldig lav", "Lav", "Mid", "Høy"];
              setAiIntervention(levels[value]);
            }}
          />
        </AccordionDetails>
      </Accordion>
    );
  };

  const verifyGeneration = () => {
    if (!image) return false;
    if ([3, 4, 5, 6, 7].includes(redesignMode) && !hasDrawn) return false;
    return true;
  };

  const getRedesignDescription = () => {
    switch (redesignMode) {
      case 0:
        return "Gi rommet ditt en liten oppgradering! Se fornyelsen utfolde seg mens du leker med ulike stiler og ideer.";
      case 1:
        return "Kjempekreativ du er da, flinkisen.";
      case 2:
        return "Legg til litt ekstra sjarm! Tegn på bildet for å legge til møbler og dekorasjon, og la kreativiteten din flyte fritt.";
      case 3:
        return "Rydd plass til forandring! Fjern unødvendige elementer og skap et blankt lerret for nye ideer.";
      case 4:
        return "Legg til et snev av farge! Prøv forskjellige farger og teksturer ved å tegne over overflater, og gi rommet ditt et friskt pust.";
      case 5:
        return "Fjern alt rundt det du tegner!";
      case 6:
        return "Fyll inn hvor du vil ha stæsjet ditt!";
      case 7:
        return "Tegn over for nytt materiale!";
      case 8:
        return "Se dine kreative ideer ta form! Konverter enkle skisser til realistiske visualiseringer og bli inspirert av dine egne design.";
    }
  };

  return (
    <Box sx={{
      minHeight: "100vh",
      backgroundColor: "#FAF9F7",
      py: 8,
      mt: "64px",
    }}>
      <SEO
        title="LønnLyst - Generer Ditt Nye Rom"
        description="Skap ditt drømmerom med vår AI-drevne designgenerator."
        name="Lønnlyst - Romgenerator"
        type="tool"
      />
      <Container maxWidth="lg">
        <Typography
          variant="h1"
          sx={{
            fontSize: { xs: "2.5rem", md: "3.5rem" },
            fontWeight: 700,
            color: "#2C1810",
            mb: 4,
            textAlign: "center",
          }}
        >
          Generer Ditt Nye Rom
        </Typography>

        <Typography
          variant="body1"
          sx={{
            fontSize: "1.1rem",
            color: "#4A4A4A",
            mb: 6,
            textAlign: "center",
          }}
        >
          Ta bilde, velg stil og se dine egne rom forandre seg.
        </Typography>

        <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
          <CameraCapture
            showCanvas={[2, 3, 4, 5, 7].includes(redesignMode)}
          />
        </Box>

        {image ? (
          <>
            <Box sx={{ mb: 4 }}>
              <ToggleButtons
                onSelectionChange={handleRedesignChange}
                values={[0, 2, 3, 4, 8]}
                labels={["Forny", "Fyll", "Tøm", "Farg", "Design"]}
              />
            </Box>

            <Typography
              variant="body1"
              sx={{
                color: "#4A4A4A",
                mb: 4,
                textAlign: "center",
              }}
            >
              {getRedesignDescription()}
            </Typography>

            {redesignMode !== 3 && (
              <>
                <Box sx={{ mb: 4 }}>
                  <ToggleButtons
                    onSelectionChange={handleRequestTypeChange}
                    values={["Interior", "Exterior", "Garden"]}
                    labels={["Interiør", "Eksteriør", "Hage"]}
                  />
                </Box>

                <Box sx={{ mb: 4 }}>
                  <CustomInputTextField
                    label="Fritekst"
                    onChange={(value) => dispatch(setPrompt({ prompt: value }))}
                    adornment={<AccountCircle />}
                    placeholder="Noe spesielt du ønsker deg?"
                    tooltip="Har du noen ønsker?"
                  />
                </Box>
              </>
            )}

            <Box sx={{ mb: 4 }}>
              <StyleDropdowns />
            </Box>
          </>
        ) : (
          <Typography
            variant="body1"
            sx={{
              color: "#4A4A4A",
              mb: 4,
              textAlign: "center",
            }}
          >
            Først må du laste opp ett bilde av det du vil se forandret. Hvis du
            går <Link href="/faq" color="primary">hit</Link> så kan du få litt tips og
            inspirasjon for å ta bedre bilder.
          </Typography>
        )}

        {testerMode && <ExtraDesignOptions />}

        {profile && (
          <Box sx={{ textAlign: "center", mb: 4 }}>
            <Typography
              variant="body1"
              sx={{
                color: "#4A4A4A",
                mb: 2,
              }}
            >
              Du har {Math.floor(profile.credits / 5)} genereringer igjen!
            </Typography>

            {profile.credits >= 5 ? (
              <CustomButton
                disabled={!verifyGeneration()}
                onClick={createRedesign}
                startIcon={<AutoAwesome />}
              >
                Lag design
              </CustomButton>
            ) : (
              <CustomButton
                onClick={() => navigate("/priser")}
                startIcon={<ShoppingCart />}
                sx={{
                  backgroundColor: "#2C1810",
                  color: "#FFFFFF",
                  '&:hover': {
                    backgroundColor: "#4A4A4A",
                  },
                }}
              >
                Kjøp bilder
              </CustomButton>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};