import { Typography, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { TokenPurchase } from 'components/TokenPurchase';
import { SEO } from 'components/SEO';
import { useEffect } from 'react';

export const Credits = () => {
	const theme = useTheme();
	const profile = useSelector((state) => state.global.profile);
  
	useEffect(() => {
	  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}, []);
  
	return (
	  <Box sx={{
		minHeight: '100vh',
		backgroundColor: '#FAF9F7',
		py: 8,
	  }}>
		<SEO
		  title="LønnLyst - Våre fleksible prispakker"
		  description="Her kan du kjøpe flere genereringer av bilder fra LønnLyst!"
		  name="Lønnlyst - Våre fleksible prispakker"
		  type="tool"
		/>
		<Container maxWidth="lg">
		  <Typography
			variant="h1"
			sx={{
			  fontSize: { xs: '2.5rem', md: '3.5rem' },
			  fontWeight: 700,
			  color: '#2C1810',
			  mb: 4,
			  mt: 20,
			  textAlign: 'center',
			}}
		  >
			Våre fleksible prispakker
		  </Typography>
		  <Typography
			variant="body1"
			sx={{
			  fontSize: '1.1rem',
			  color: '#4A4A4A',
			  mb: 6,
			  textAlign: 'center',
			  maxWidth: '600px',
			  mx: 'auto',
			}}
		  >
			Velg den pakken som passer best for dine behov og start å forvandle dine rom i dag.
		  </Typography>
		  <Box
			sx={{
			  display: 'flex',
			  flexDirection: 'column',
			  alignItems: 'center',
			}}
		  >
			{profile && <TokenPurchase />}
		  </Box>
		</Container>
	  </Box>
	);
  };