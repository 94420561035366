import { useState } from 'react';
import {
  Button,
  useTheme,
  Stack,
  useMediaQuery,
  IconButton,
  Box,
} from '@mui/material';
import { MenuRounded, Close, Feedback } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { startLogin } from './ApiActions';
import FlexBetween from './FlexBetween';
import { setIsWriting, setIsFinished, setIsAborted, setFeedbackOpen } from 'state';

const NavContainer = styled(Box)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  borderRadius: '100px',
  padding: '4px',
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  margin: '0 auto',
  boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)'
}));

const NavItem = styled(Button)(({ theme, active }) => ({
  color: active ? '#000000' : 'rgba(0, 0, 0, 0.7)',
  backgroundColor: active ? 'white' : 'transparent',
  border: '1px solid transparent',
  borderRadius: '100px',
  padding: '8px 16px',
  margin: '0 4px',
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 500,
  position: 'relative',
  boxShadow: active ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
  '&:hover': {
    backgroundColor: active ? 'white' : 'rgba(255, 255, 255, 0.5)',
  },
}));

const FeedbackButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.grey[800],
  color: 'white',
  borderRadius: '100px',
  padding: '6px 16px',
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 500,
  '&:hover': {
    backgroundColor: theme.palette.grey[900],
  },
}));

export const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery('(min-width: 1000px)');
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const hasProfile = Boolean(
    useSelector((state) => state.global.profile) !== null
  );
  const profile = useSelector((state) => state.global.profile);
  const user = useSelector((state) => state.global.user);

  const doStartLogin = () => {
    startLogin();
    setIsMobileMenuToggled(!isMobileMenuToggled);
  };

  const handleNavigate = (location) => {
    navigate(location);
    setIsMobileMenuToggled(false);
  };

  const handleHomeClick = () => {
    navigate('/');
    setIsMobileMenuToggled(false);
    dispatch(setIsAborted({ isAborted: false }));
    dispatch(setIsWriting({ isWriting: false }));
    dispatch(setIsFinished({ isFinished: false }));
  };

  const isAuthorized = user && user.isAuthorized;
  return (
    <Box
      component="header"
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: 'white',
        py: 2,
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: '1400px',
          margin: '0 auto',
          px: 3,
        }}
      >
        <img
          src={
            isNonMobileScreens
              ? 'https://pixygontech.b-cdn.net/LLLogoWide.png'
              : 'https://pixygontech.b-cdn.net/LLLogo512.png'
          }
          alt="Logo"
          width={isNonMobileScreens ? '192' : '40'}
          height="auto"
          style={{ cursor: 'pointer' }}
          onClick={handleHomeClick}
        />

        {isNonMobileScreens ? (
          <>
            <NavContainer>
              <NavItem 
                active={location.pathname === '/'} 
                onClick={handleHomeClick}
              >
                Home
              </NavItem>
              {user && (
                <NavItem 
                  active={location.pathname === '/images'} 
                  onClick={() => handleNavigate('/images')}
                >
                  Mine bilder
                </NavItem>
              )}
              <NavItem 
                active={location.pathname === '/faq'} 
                onClick={() => handleNavigate('/faq')}
              >
                FAQ
              </NavItem>
              {user && (
                <NavItem 
                  active={location.pathname === '/priser'} 
                  onClick={() => handleNavigate('/priser')}
                >
                  Priser
                </NavItem>
              )}
            </NavContainer>

            <Stack direction="row" spacing={2} alignItems="center">
              <FeedbackButton
                onClick={() => dispatch(setFeedbackOpen({ open: true }))}
                startIcon={<Feedback />}
              >
                Feedback
              </FeedbackButton>

              {user ? (
                <NavItem
                  active={location.pathname === '/profile'}
                  onClick={() => handleNavigate('/profile')}
                >
                  {hasProfile && <>{profile.credits} mynter</>}
                  Hei {user.given_name}!
                </NavItem>
              ) : (
                <Box onClick={doStartLogin}>
                  <vipps-mobilepay-button
                    type="button"
                    brand="vipps"
                    language="no"
                    variant="primary"
                    rounded="true"
                    verb="login"
                    stretched="false"
                    branded="true"
                    loading="false"
                  />
                </Box>
              )}
            </Stack>
          </>
        ) : (
          <IconButton
            onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            sx={{
              color: theme.palette.primary.main,
            }}
          >
            {isMobileMenuToggled ? <Close /> : <MenuRounded />}
          </IconButton>
        )}
      </Stack>

      {/* Mobile Menu */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          top="70px"
          height="270px"
          zIndex="10"
          minWidth="100%"
          padding="1rem 10%"
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <NavContainer sx={{ flexDirection: 'column', width: '100%', p: 2 }}>
            <NavItem 
              active={location.pathname === '/'}
              onClick={handleHomeClick}
              sx={{ width: '100%', mb: 1 }}
            >
              Home
            </NavItem>
            {user && (
              <NavItem 
                active={location.pathname === '/images'}
                onClick={() => handleNavigate('/images')}
                sx={{ width: '100%', mb: 1 }}
              >
                Mine bilder
              </NavItem>
            )}
            <NavItem 
              active={location.pathname === '/faq'}
              onClick={() => handleNavigate('/faq')}
              sx={{ width: '100%', mb: 1 }}
            >
              FAQ
            </NavItem>
            {user && (
              <NavItem 
                active={location.pathname === '/priser'}
                onClick={() => handleNavigate('/priser')}
                sx={{ width: '100%', mb: 1 }}
              >
                Priser
              </NavItem>
            )}
            <FeedbackButton
              onClick={() => dispatch(setFeedbackOpen({ open: true }))}
              startIcon={<Feedback />}
              sx={{ width: '100%', mb: 1 }}
            >
              Feedback
            </FeedbackButton>
            {user ? (
              <NavItem
                active={location.pathname === '/profile'}
                onClick={() => handleNavigate('/profile')}
                sx={{ width: '100%' }}
              >
                Hei {user.given_name}!
              </NavItem>
            ) : (
              <Box onClick={doStartLogin} sx={{ width: '100%', textAlign: 'center' }}>
                <vipps-mobilepay-button
                  type="button"
                  brand="vipps"
                  language="no"
                  variant="primary"
                  rounded="true"
                  verb="login"
                  stretched="false"
                  branded="true"
                  loading="false"
                />
              </Box>
            )}
          </NavContainer>
        </Box>
      )}
    </Box>
  );
};